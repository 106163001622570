import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import pages from "../pages";
import { useUser } from "../services/userContext";
import { pageVisibleInNavbar } from "../utils/pageUtils";

function getVisibleSubPages(page, user) {
  return (
    page.subPages && page.subPages.filter((p) => pageVisibleInNavbar(p, user))
  );
}

export default function NavButtons() {
  const [anchorElem, setAnchorElem] = useState(null);
  const [subPages, setSubPages] = useState(null);
  const navigate = useNavigate();
  const { user } = useUser();
  const { pathname: path } = useLocation();

  return (
    <>
      {pages.map((page) => {
        const visibleSubPages = getVisibleSubPages(page, user);
        const hasSubPages = visibleSubPages && visibleSubPages.length > 0;
        const isActive = path.startsWith(page.path);
        const visibleToUser = pageVisibleInNavbar(page, user);
        return (
          visibleToUser && (
            <Button
              key={page.name}
              onClick={(e) => {
                if (hasSubPages) {
                  setAnchorElem(e.currentTarget);
                  setSubPages(visibleSubPages);
                } else navigate(page.path);
              }}
              sx={{ padding: 1.5, pr: 3, pl: 3, ml: 0.5, mr: 0.5 }}
              color={isActive ? "secondary" : "inherit"}
              endIcon={hasSubPages ? <ExpandMoreIcon /> : null}
            >
              {page.name}
            </Button>
          )
        );
      })}
      <Menu
        anchorEl={anchorElem}
        open={Boolean(anchorElem)}
        onClose={() => setAnchorElem(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ mt: 0.5 }}
      >
        {subPages?.map((page, i) => {
          const isActive = path.startsWith(page.path);
          return (
            <MenuItem
              key={page.name}
              onClick={(e) => {
                setAnchorElem(null);
                navigate(page.path);
              }}
              sx={{ justifyContent: "flex-start" }}
            >
              <Typography
                variant="body1"
                textAlign="left"
                color={isActive ? "secondary" : "inherit"}
              >
                {page.name}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
